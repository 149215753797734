<template>
    <v-progress-circular
        v-if="loading.initial"
        :size="50"
        :color="$const.color.primary"
        indeterminate
        class="d-flex mx-auto"
    ></v-progress-circular>
    <v-form v-else>
        <v-text-field
            v-model="$v.form.year.$model"
            :error-messages="getErrors('form.year')"
            label="Год"
            type="number" 
            :color="$const.color.primary"
        ></v-text-field>

        <v-select
            v-model="$v.form.subject.$model"
            :error-messages="getErrors('form.subject')"
            required
            :items="subjects"
            label="Предмет"
        />

        <v-select
            v-model="$v.form.grade.$model"
            :disabled="!$v.form.subject.$model"
            :error-messages="getErrors('form.grade')"
            required
            :items="grades"
            label="Класс"
        />
        
        <v-select
            v-model="$v.form.category.$model"
            :error-messages="getErrors('form.category')"
            label="Категория"
            :items="categories"
            :color="$const.color.primary" 
        />

        <v-select
            v-if="form.category === 'control'"
            v-model="$v.form.collection.$model"
            :error-messages="getErrors('form.collection')"
            required
            :items="collections"
            label="Коллекция"
            name="collection"
        ></v-select>
        
        <template v-if="serverErrors && serverErrors.summary" >
        <v-alert dense type="error">
            {{ serverErrors.summary }}
        </v-alert>
        </template>

        <v-divider class="my-4" />
        <v-btn
            outlined
            :disabled="!canRequest"
            :loading="loading.submit"
            @click="submit"
        >Скачать</v-btn>
    </v-form>
</template>

<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ApiHelper from '@/helpers/ApiHelper'
import { JOB_SERVER, JOB_SERVER_API_KEY } from '@/servers'

export default {
    name: 'TaskScoresReportForm',
    mixins: [errorMixin, saveMixin, validationMixin],
    data () {
        return {
            loading: {
                initial: false,
                submit: false
            },
            categories: [{value: 'test', text: 'ВПР'}, {value: 'control', text: 'Контрольная работа'}],
            form: {
                year: (new Date()).getFullYear(),
                subject: '',
                grade: 4,
                category: 'test',
                collection: 1
            }
        };
    },
    validations() {
        return {
            form: {
                year: { required },
                subject: { required },
                grade: { required },
                category: { required },
                collection: { required }
            }
        }
    },
    watch: {
        'form.category'() {
            if (this.form.category === 'test')
                this.form.collection = 1
        }
    },
    computed: {
        subjects () {
            return this.$store.state.app.subjects
        },
        grades () {
            if (!this.form.subject) { return [] }
            return this.subjects.find(item => item.value === this.form.subject)?.grades.flat()
        },
        canRequest () {
            return  this.form.grade &&
                    this.form.subject &&
                    this.form.year &&
                    parseInt(this.form.grade) > 0
        },
        collections () {
            return (new Array(10)).fill(null).map((item, index) => index + 1)
        }
    },
    async created () {
        this.loading.initial = true
        try {
            await this.$store.dispatch('app/waitUntilRequiredDataLoaded')
        } catch (e) {
            console.error(e)
        } finally {
            this.loading.initial = false
        }
    },
    methods: {
        async submit () {
            if (this.loading.submit) { return false; }

            if (!this.validate()) {
                console.log('Validation failed');
                return false;
            }
            
            try {
                this.loading.submit = true;
                const response = await ApiHelper.request(`${JOB_SERVER}/api/report/tasks-scores-list`, 'get', {}, this.form, { api_key: JOB_SERVER_API_KEY, responseType: 'blob' });

                if (response.type !== 'text/csv') { throw new Error('Wrong response type.') }
                
                let exportUrl = URL.createObjectURL(response);
                window.location.assign(exportUrl);
                URL.revokeObjectURL(exportUrl);
            } catch (e) {
                console.error(e)
                alert('Ошибка формирования файла.')
            }
            finally {
                this.loading.submit = false;
                this.$emit('success', 1);
            }
        }
    }
}
</script>
